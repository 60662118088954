<template>
  <v-btn
    :href="hrefLink"
    :width="width"
    :disabled="isDisabled"
    :height="height"
    :color="color"
    :class="{ 'white--text': Boolean(color) }"
    @click="btnClick()"
  >
    <template v-if="Boolean(title)">
      {{ title }}
    </template>
    <template v-if="Boolean(icon)">
      <mdicon class="ak-icon" :name="icon" size="26" />
    </template>
  </v-btn>
</template>

<script>
export default {
  name: 'AKButton',
  components: {},
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: 'auto',
    },
    height: {
      type: String,
      default: 'auto',
    },
    color: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    hrefLink: {
      type: String,
      default: null,
    },
  },

  data() {
    return {};
  },
  methods: {
    btnClick() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
  box-shadow: 0px 3px 0px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  font-weight: 700;
  letter-spacing: 0px !important;

  &__content {
    text-transform: none !important;
    letter-spacing: 0px !important;
  }
}

.ak-icon {
  color: #000;
}

.v-btn:not(.v-btn--round).v-size--default {
  min-width: auto;
}

::v-deep .v-btn__content {
  text-transform: none !important;
}
</style>
