<template>
  <v-avatar
    :color="hasUserInfo ? '' : 'red'"
    class="ak-user-photo"
    :height="avatarHeight"
    :width="avatarWidth"
    :min-width="avatarWidth"
  >
    <template v-if="hasUserInfo">
      <img :src="avatar" :alt="name" />
    </template>
    <template v-else>
      <span class="white--text text-h5">{{ name }}</span>
    </template>
  </v-avatar>
</template>

<script>
export default {
  name: 'AKAvatar',
  props: {
    avatar: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    avatarHeight: {
      type: Number,
      default: 59,
    },
    avatarWidth: {
      type: Number,
      default: 59,
    },
  },
};
</script>

<style lang="scss" scoped></style>
