import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '',
    name: 'Home',
    component: () =>
      import(/* webpackChunkName: "Home" */ '@/components/pages/Home.vue'),
  },
  {
    path: '/learning-units-list',
    name: 'LearningUnitsList',
    component: () =>
      import(
        /* webpackChunkName: "LearningUnitsList" */
        '@/components/pages/LearningUnitsList.vue'
      ),
  },
  {
    path: '/learn-unit/:unitId',
    name: 'LearnUnitDetails',
    component: () =>
      import(
        /* webpackChunkName: "LearnUnitDetails" */
        '@/components/modules/LearnUnit/LearnUnitDetails.vue'
      ),
  },
  {
    path: '/challenges',
    name: 'Challenges',
    component: () =>
      import(
        /* webpackChunkName: "Challenges" */
        '@/components/pages/Challenges.vue'
      ),
  },
  {
    path: '/ranking',
    name: 'Ranking',
    component: () =>
      import(
        /* webpackChunkName: "Ranking" */
        '@/components/pages/Ranking.vue'
      ),
  },
  {
    path: '/hall-of-fame',
    name: 'HallOfFame',
    component: () => import('@/components/pages/HallOfFame.vue'),
  },
  {
    path: '/settings',
    name: 'Einstellungen',
    component: () =>
      import(
        /* webpackChunkName: "Settings" */
        '@/components/pages/Settings.vue'
      ),
  },

  {
    path: '/memory',
    name: 'MemoryQuestion',
    component: () =>
      import(
        /* webpackChunkName: "Settings" */
        '@/components/modules/games/MemoryQuestion/MemoryQuestion.vue'
      ),
  },
  {
    path: '*',
    component: () => import('@/components/common/AKNotFoundMessage'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
