import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from '@/plugins/vuetify';
import mdiVue from 'mdi-vue/v2';
import * as mdijs from '@mdi/js';
import VueDragDrop from 'vue-drag-drop';
import axios from 'axios';
import CommonMixins from '@/mixins/CommonMixins';
import VueNativeSock from 'vue-native-websocket';
import award from '@/components/common/Award';

Vue.config.productionTip = false;
Vue.use(VueDragDrop);

Vue.use(mdiVue, {
  icons: mdijs,
});

Vue.prototype.$axios = axios;
Vue.mixin(CommonMixins);

Vue.use(
  VueNativeSock,
  (location.protocol === 'https:' ? 'wss' : 'ws') +
    '://' +
    location.hostname +
    ':' +
    location.port +
    '/ws/messages',
  {
    reconnection: true, // (Boolean) whether to reconnect automatically (false)
    reconnectionAttempts: 5, // (Number) number of reconnection attempts before giving up (Infinity),
    reconnectionDelay: 3000, // (Number) how long to initially wait before attempting a new (1000)
  }
);

const resource = '/oauth/login/anaking';

new Vue({
  router,
  store,
  vuetify,
  beforeCreate() {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 401) {
          window.location.assign(resource);
        } else {
          return Promise.reject(error);
        }
      }
    );
    this.$store.dispatch('user/loadUserData');
  },
  render: (h) => h(App),
  created() {
    this.$options.sockets.onmessage = (data) => this.messageReceived(data);
  },
  methods: {
    messageReceived(message) {
      if (!message.data.toString().startsWith('<<')) {
        let data = JSON.parse(message.data);

        if (data.awards?.length > 0) {
          let awardText = '';
          if (data.awards?.length > 1) {
            awardText = 'Neue Awards gewonnen \uD83C\uDFC6';
          } else {
            awardText = 'Neuer Award gewonnen \uD83C\uDFC6';
          }
          this.$store.dispatch('setSnackData', {
            snackStatus: true,
            snackError: false,
            snackColor: '#6DB98D',
            snackText: awardText,
            snackLinkName: 'HallOfFame',
          });
        }
      }
    },
  },
}).$mount('#app');
