var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hof-award mb-2",class:{
    'hof-tile-background': _vm.isNotAwarded,
    'hof-unlocked': _vm.isAwarded & !_vm.isActive,
    'hof-active': _vm.isAwarded & _vm.isActive,
  },on:{"click":_vm.showAwardDetails}},[(_vm.isNotAwarded)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-center hof-locked"},[_c('mdicon',{attrs:{"name":"Lock"}})],1)])],1):_vm._e(),(_vm.isAwarded)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-center"},[_c('div',{staticClass:"hof-circle-base",class:_vm.awardDetails.background},[_c('p',{staticClass:"hof-unlocked-emoji"},[_vm._v(_vm._s(_vm.awardDetails.emoji))])])])])],1):_vm._e(),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"d-flex justify-center",class:{
              'margin-no-count': _vm.getCountText(),
              'hof-name': !_vm.isActive,
              'hof-name-active': _vm.isActive,
            }},[_vm._v(" "+_vm._s(_vm.awardDetails.name)+" ")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"d-flex justify-center",class:{
              'hof-count': !_vm.isActive,
              'hof-count-active': _vm.isActive,
            }},[_vm._v(" "+_vm._s(_vm.getCountText())+" ")])])],1)],1)],1),_c('AwardDetailsModal',{attrs:{"isOpen":_vm.isDetailsOpen,"name":_vm.awardDetails.name,"emoji":_vm.awardDetails.emoji,"description":_vm.awardDetails.description,"count":_vm.awardDetails.count,"circleBackground":_vm.awardDetails.background},on:{"closeAwardDetailsModal":_vm.closeModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }