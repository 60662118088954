<template>
  <v-snackbar
    top
    width="100%"
    v-model="snackIsOpen"
    :timeout="snackError ? -1 : 5000"
    :color="snackColor"
    contentClass="text-center"
  >
    <div
      v-if="hasSnackLinkName"
      @click="goToLinkName(snackLinkName)"
      style="cursor: pointer"
    >
      {{ snackText }}
    </div>
    <template v-else>{{ snackText }}</template>

    <template v-slot:action="{ attrs }">
      <span @click="closeDialog()">
        <mdicon v-bind="attrs" class="ak-icon" name="Close" size="22" />
      </span>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'AKSnackbar',

  data() {
    return {
      snackIsOpen: this.$store.state.snackbar.snackStatus,
    };
  },

  computed: {
    snackText() {
      return this.$store.state.snackbar.snackText;
    },

    snackColor() {
      return this.$store.state.snackbar.snackColor;
    },

    snackError() {
      return this.$store.state.snackbar.snackError;
    },

    hasSnackLinkName() {
      return this.$store.state.snackbar.snackLinkName != null;
    },

    snackLinkName() {
      return this.$store.state.snackbar.snackLinkName;
    },
  },

  watch: {
    snackIsOpen(val) {
      if (!val) {
        this.$store.dispatch('clearSnackData');
      }
    },
  },

  methods: {
    closeDialog() {
      this.$store.dispatch('clearSnackData');
    },
    async goToLinkName(linkName) {
      await this.$router
        .push({ name: linkName })
        .catch((err) => {
          console.log(err);
        })
        .then(await this.$store.dispatch('clearSnackData'));
    },
  },
};
</script>

<style lang="scss" scoped>
.ak-icon {
  cursor: pointer;
}
</style>
