export const mutations = {
  clearSnackData(state) {
    state.snackbar.snackStatus = false;
    state.snackbar.snackError = false;
    state.snackbar.snackText = '';
    state.snackbar.snackColor = '';
  },

  setSnackData(state, { snackInfo }) {
    state.snackbar = snackInfo;
  },
};
