<template>
  <div
    class="hof-award mb-2"
    :class="{
      'hof-tile-background': isNotAwarded,
      'hof-unlocked': isAwarded & !isActive,
      'hof-active': isAwarded & isActive,
    }"
    @click="showAwardDetails"
  >
    <v-row no-gutters v-if="isNotAwarded">
      <v-col cols="12">
        <div class="d-flex justify-center hof-locked">
          <mdicon name="Lock" />
        </div>
      </v-col>
    </v-row>

    <v-row no-gutters v-if="isAwarded">
      <v-col cols="12">
        <div class="d-flex justify-center">
          <div class="hof-circle-base" :class="awardDetails.background">
            <p class="hof-unlocked-emoji">{{ awardDetails.emoji }}</p>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12">
        <v-row>
          <v-col cols="12">
            <p
              class="d-flex justify-center"
              :class="{
                'margin-no-count': getCountText(),
                'hof-name': !isActive,
                'hof-name-active': isActive,
              }"
            >
              {{ awardDetails.name }}
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <p
              class="d-flex justify-center"
              :class="{
                'hof-count': !isActive,
                'hof-count-active': isActive,
              }"
            >
              {{ getCountText() }}
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <AwardDetailsModal
      :isOpen="isDetailsOpen"
      :name="awardDetails.name"
      :emoji="awardDetails.emoji"
      :description="awardDetails.description"
      :count="awardDetails.count"
      :circleBackground="awardDetails.background"
      @closeAwardDetailsModal="closeModal"
    />
  </div>
</template>

<script>
import AwardDetailsModal from '@/components/common/AwardDetailsModal';

export default {
  name: 'Award',
  components: { AwardDetailsModal },
  data() {
    return {
      isDetailsOpen: false,
    };
  },
  props: {
    awardKey: {
      type: String,
      required: true,
    },
    data: {
      type: Array,
      default: null,
    },
    awardDetails: {
      type: Object,
      default: null,
    },
  },
  methods: {
    showAwardDetails() {
      if (this.isAwarded) {
        this.isDetailsOpen = true;
        this.$emit('acknowledgeAward', this.awardKey);
      }
    },
    closeModal() {
      this.isDetailsOpen = false;
    },
    getCountText() {
      if (this.awardDetails.count > 1) {
        return this.awardDetails.count + 'x ';
      }
      return '';
    },
  },
  computed: {
    isAwarded() {
      return this.data !== null;
    },
    isNotAwarded() {
      return this.data === null;
    },
    isActive() {
      if (this.isNotAwarded) {
        return false;
      }
      return this.data.filter((it) => !it.acknowledged).length > 0;
    },
  },
};
</script>

<style scoped>
.hof-award {
  position: relative;
  width: 111px;
  height: 111px;
}

.hof-tile-background {
  background-color: #c4c4c4;
}

.hof-locked {
  margin: 25px;
  color: #6e6e6e;
}

.hof-unlocked {
  border-width: 1px;
  border-color: #e6e6e6;
  border-style: solid;
  cursor: pointer;
}

.hof-active {
  border-width: 2px;
  border-color: #6db98d;
  border-style: solid;
  cursor: pointer;
}

.hof-unlocked-emoji {
  text-align: center;
  margin-top: 4px;
  color: #6e6e6e;
  font-size: 40px;
  line-height: 60px;
}

.hof-name {
  font-size: 14px;
  line-height: 16px;
  margin-bottom: -25px;
}

.hof-name-active {
  font-size: 14px;
  line-height: 16px;
  margin-bottom: -26px;
}

.hof-count {
  font-size: 14px;
  line-height: 16px;
  margin-top: -10px;
}

.hof-count-active {
  font-size: 14px;
  line-height: 16px;
  margin-top: -11px;
}

.hof-circle-base {
  margin-top: 6px;
  margin-bottom: 3px;
  border-radius: 50%;
  width: 66px;
  height: 66px;
}

.hof-background-ana-baby {
  background: linear-gradient(159.81deg, #79a2f0 4.48%, #b14ea9 98.55%);
}

.hof-background-ana-fan {
  background: linear-gradient(159.81deg, #ffb6b6 4.48%, #b14ea9 98.55%);
}

.hof-background-ana-star {
  background: linear-gradient(159.81deg, #f0cf79 4.48%, #b14ea9 98.55%);
}

.hof-background-number-one {
  background: linear-gradient(159.81deg, #bdfc6c 4.48%, #b14ea9 98.55%);
}

.hof-background-schlaumeier {
  background: linear-gradient(159.81deg, #6cfcc8 4.48%, #b14ea9 98.55%);
}

.hof-background-kolumbus {
  background: linear-gradient(159.81deg, #6ce2fc 4.48%, #b14ea9 98.55%);
}

.hof-background-drunken-sailor {
  background: linear-gradient(159.81deg, #bd6cfc 4.48%, #b1964e 98.55%);
}

.hof-background-blindes-huhn {
  background: linear-gradient(159.81deg, #fc6c6c 4.48%, #b14ea9 98.55%);
}

.hof-background-whisky {
  background: linear-gradient(159.81deg, #6cfcc8 4.48%, #4eabb1 98.55%);
}

.hof-background-teflon {
  background: linear-gradient(159.81deg, #dffc6c 4.48%, #4eabb1 98.55%);
}

.hof-background-fleissige-biene {
  background: linear-gradient(159.81deg, #fca06c 4.48%, #4eabb1 98.55%);
}

.hof-background-ana-bond {
  background: linear-gradient(159.81deg, #fc6cba 4.48%, #4eabb1 98.55%);
}
</style>
