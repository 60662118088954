<template>
  <v-dialog
    v-model="isOpen"
    width="80vw"
    persistent
    max-width="700"
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <div class="hof-award-details">
      <p
        class="hof-details-name"
        :class="{
          'hof-details-name-pad': this.getCountText() === '',
        }"
      >
        {{ name }}
      </p>
      <p class="hof-details-count">{{ getCountText() }}</p>
      <div class="hof-circle-border-base">
        <div class="hof-circle-base" :class="circleBackground">
          <p class="hof-details-emoji">{{ emoji }}</p>
        </div>
      </div>
      <p class="hof-details-description">{{ description }}</p>
    </div>
    <div class="pb-5 px-4 hof-close-button">
      <AKButton
        height="50"
        :width="$vuetify.breakpoint.smAndDown ? '100%' : '200px'"
        title="Schliessen"
        :color="$vuetify.theme.themes.light.brightBlue"
        @click="closeAwardDetailsModal()"
      />
    </div>
  </v-dialog>
</template>

<script>
import AKButton from '@/components/common/core/AKButton';

export default {
  name: 'AwardDetailsModal',
  components: { AKButton },
  props: {
    name: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    emoji: {
      type: String,
      default: null,
    },
    count: {
      type: Number,
      default: 0,
    },
    circleBackground: {
      type: String,
      default: null,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeAwardDetailsModal() {
      this.$emit('closeAwardDetailsModal');
    },
    getCountText() {
      if (this.count > 1) {
        return this.count + 'x gewonnen';
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.hof-award-details {
  padding-top: 51px;
  position: relative;
  background: var(--v-primary-base);
  padding-bottom: 30px;
  height: 100%;

  overflow: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}

.hof-details-name {
  color: var(--v-secondary-base);
  font-style: normal;
  line-height: 33px;
  font-weight: 700;
  text-align: center;
  font-size: 28px;
}

.hof-details-name-pad {
  padding-bottom: 23px;
}

.hof-details-count {
  color: var(--v-secondary-base);
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  text-align: center;
  font-size: 20px;
  padding-bottom: 28px;
}

.hof-details-emoji {
  padding-top: 6px;
  text-align: center;
  font-size: 130px;
  line-height: 180px;
}

.hof-circle-base {
  margin: 0 auto;
  padding-top: 10px;
  position: relative;
  border-radius: 50%;
  width: 204px;
  height: 204px;
}

.hof-circle-border-base {
  position: relative;
  margin: 0 auto;
  padding-top: 3px;
  border-radius: 50%;
  width: 210px;
  height: 210px;
  background: var(--v-secondary-base);
}

.hof-details-description {
  color: var(--v-secondary-base);
  text-align: center;
  font-size: 20px;
  line-height: 23px;
  font-style: normal;
  font-weight: 400;
  padding-bottom: 34px;
  padding-top: 51px;
}

.hof-close-button {
  background: var(--v-primary-base);

  @media (max-width: map-get($grid-breakpoints, md)) {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: var(--v-primary-base);
  }

  @media (min-width: map-get($grid-breakpoints, lg)) {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

::v-deep .v-dialog--fullscreen {
  overflow: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}

.hof-background-ana-baby {
  background: linear-gradient(159.81deg, #79a2f0 4.48%, #b14ea9 98.55%);
}

.hof-background-ana-fan {
  background: linear-gradient(159.81deg, #ffb6b6 4.48%, #b14ea9 98.55%);
}

.hof-background-ana-star {
  background: linear-gradient(159.81deg, #f0cf79 4.48%, #b14ea9 98.55%);
}

.hof-background-number-one {
  background: linear-gradient(159.81deg, #bdfc6c 4.48%, #b14ea9 98.55%);
}

.hof-background-schlaumeier {
  background: linear-gradient(159.81deg, #6cfcc8 4.48%, #b14ea9 98.55%);
}

.hof-background-kolumbus {
  background: linear-gradient(159.81deg, #6ce2fc 4.48%, #b14ea9 98.55%);
}

.hof-background-drunken-sailor {
  background: linear-gradient(159.81deg, #bd6cfc 4.48%, #b1964e 98.55%);
}

.hof-background-blindes-huhn {
  background: linear-gradient(159.81deg, #fc6c6c 4.48%, #b14ea9 98.55%);
}

.hof-background-whisky {
  background: linear-gradient(159.81deg, #6cfcc8 4.48%, #4eabb1 98.55%);
}

.hof-background-teflon {
  background: linear-gradient(159.81deg, #dffc6c 4.48%, #4eabb1 98.55%);
}

.hof-background-fleissige-biene {
  background: linear-gradient(159.81deg, #fca06c 4.48%, #4eabb1 98.55%);
}

.hof-background-ana-bond {
  background: linear-gradient(159.81deg, #fc6cba 4.48%, #4eabb1 98.55%);
}
</style>
