<script>
export default {
  name: 'CommonMixins',
  data() {
    return {
      axiosPostPutConfig: {
        headers: {
          'Content-Type': 'application/json',
          'Content-Language': 'de',
        },
      },
      axiosGetConfig: {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': 'de',
        },
      },
    };
  },
  computed: {
    isTouchDevice() {
      return window.ontouchstart !== undefined;
    },
    hasUserInfo() {
      return this.$store.state['user'].userInformation.isLoaded;
    },
    userInfo() {
      return this.hasUserInfo
        ? this.$store.state['user'].userInformation.userData
        : '';
    },
  },
  methods: {},
};
</script>
