<template>
  <v-app id="app" :class="{ 'ak-blur': !hasUserInfo }">
    <template v-if="$store.state.snackbar.snackStatus">
      <AKSnackbar />
    </template>

    <template v-if="$vuetify.breakpoint.mdAndUp">
      <HeaderNavigation
        v-if="hasUserInfo"
        :hasUserInfo="hasUserInfo"
        :avatar="userInfo.avatar"
        :name="userInfo.nickname || userInfo.name"
        :ranking="userInfo.ranking"
        :score="userInfo.score"
        :average-stars="userInfo.averageStars"
      />
    </template>

    <v-main v-if="hasUserInfo">
      <router-view />
    </v-main>

    <template v-if="!hasUserInfo">
      <div class="ak-blur-page">
        <p class="font-roboto ak-blur-page-message ak-colorText-sandWhite">
          Sie werden aktuell angemeldet. Bitte warten Sie. Sollten Sie nicht
          weitergeleitet werden, kontaktieren Sie den Support via E-Mail.
          <a
            class="font-roboto ak-colorText-sandWhite"
            href="mailto:anaking.gesundheit@zhaw.ch"
          >
            anaking.gesundheit@zhaw.ch
          </a>
        </p>
      </div>
    </template>
  </v-app>
</template>

<script>
import HeaderNavigation from '@/components/common/HeaderNavigation';
import AKSnackbar from '@/components/common/core/AKSnackbar';
export default {
  name: 'App',
  components: { HeaderNavigation, AKSnackbar },
  computed: {
    hasUserInfo() {
      return this.$store.state['user'].userInformation.isLoaded;
    },
    userInfo() {
      return this.hasUserInfo
        ? this.$store.state['user'].userInformation.userData
        : null;
    },
  },

  created() {
    window.scrollTo(0, 1);
  },
};
</script>

<style lang="scss" scoped>
.v-main {
  background-color: var(--v-primary-base);

  &.guidline {
    background-color: #fff;
  }
}

.ak-blur-page {
  background-color: var(--v-primary-base);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9999;
  backdrop-filter: blur(2px);
  display: flex;
  justify-content: center;
  align-items: center;

  &-message {
    font-size: 32px;
    text-shadow: 0 0 6px #000;
    text-align: center;
  }
}
</style>
